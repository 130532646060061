import type { Nullable } from "./common"

export const RiskLevel = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
} as const

export type RiskUnion = (typeof RiskLevel)[keyof typeof RiskLevel]

export namespace Risk {
  export function getRiskLevelText(risk: Nullable<RiskUnion>, female = false) {
    if (!risk) return null
    const { tsafe } = useI18nUtils({ useScope: "global" })
    const translationKey = female ? "finq_risks_female" : "finq_risks"
    return tsafe(`common.${translationKey}.${risk}`)
  }

  export function getRiskColor(riskLevel: Nullable<RiskUnion>, opacity = 1) {
    return `rgba(${RGB_COLORS?.[riskLevel!] ?? "255, 255, 255"}, ${opacity})`
  }

  export const COLORS = {
    [RiskLevel.One]: "#7FEB70",
    [RiskLevel.Two]: "#32CD8C",
    [RiskLevel.Three]: "#28A5C8",
    [RiskLevel.Four]: "#3F7FE0",
    [RiskLevel.Five]: "#914BE6",
  }

  export const RGB_COLORS = {
    [RiskLevel.One]: "140, 225, 113", // #7FEB70
    [RiskLevel.Two]: "87, 192, 133", // #32CD8C
    [RiskLevel.Three]: "66, 153, 189", // #28A5C8
    [RiskLevel.Four]: "64, 117, 213", // #3F7FE0
    [RiskLevel.Five]: "145, 75, 230", // #914BE6
  }

  export const BACKGROUND_GRADIENTS = {
    [RiskLevel.One]:
      "linear-gradient(180deg, rgba(120, 255, 102, 0.20) 0%, rgba(120, 255, 102, 0.15) 25%, rgba(120, 255, 102, 0.05) 60%, rgba(120, 255, 102, 0.00) 85%, rgba(120, 255, 102, 0.00) 100%)",
    [RiskLevel.Two]:
      "linear-gradient(180deg, rgba(102, 255, 191, 0.20) 0%, rgba(102, 255, 190, 0.15) 25%, rgba(102, 255, 190, 0.05) 60%, rgba(102, 255, 190, 0.00) 85%, rgba(92, 255, 186, 0.00) 100%)",
    [RiskLevel.Three]:
      "linear-gradient(180deg, rgba(102, 222, 255, 0.20) 0%, rgba(102, 222, 255, 0.15) 25%, rgba(102, 222, 255, 0.05) 60%, rgba(102, 222, 255, 0.00) 85%, rgba(92, 220, 255, 0.00) 100%)",
    [RiskLevel.Four]:
      "linear-gradient(180deg, rgba(122, 175, 255, 0.20) 0%, rgba(122, 175, 255, 0.15) 25%, rgba(122, 175, 255, 0.05) 60%, rgba(122, 175, 255, 0.00) 85%, rgba(122, 175, 255, 0.00) 100%)",
    [RiskLevel.Five]:
      "linear-gradient(180deg, rgba(199, 153, 255, 0.20) 0%, rgba(199, 153, 255, 0.15) 25%, rgba(199, 153, 255, 0.05) 60%, rgba(199, 153, 255, 0.00) 85%, rgba(190, 138, 255, 0.00) 100%)",
  }

  export const TEST_GRADIENT = {
    [RiskLevel.One]: "rgba(79, 250, 56, 0.30)",
    [RiskLevel.Two]: "rgba(61, 245, 167, 0.30)",
    [RiskLevel.Three]: "rgba(87, 219, 255, 0.30)",
    [RiskLevel.Four]: "rgba(138, 185, 255, 0.30)",
    [RiskLevel.Five]: "rgba(205, 163, 255, 0.30)",
  }

  export const RANK_GRADIENTS = {
    [RiskLevel.One]:
      "linear-gradient(180deg, rgba(79, 250, 56, 0.30) 0%, rgba(79, 250, 56, 0.30) 0.01%, rgba(79, 250, 56, 0.20) 50%, rgba(79, 250, 56, 0.00) 100%)",
    [RiskLevel.Two]:
      "linear-gradient(180deg, rgba(61, 245, 167, 0.30) 0%, rgba(61, 245, 167, 0.20) 50%, rgba(61, 245, 167, 0.00) 100%)",
    [RiskLevel.Three]:
      "linear-gradient(180deg, rgba(87, 219, 255, 0.30) 0%, rgba(87, 219, 255, 0.20) 50%, rgba(87, 219, 255, 0.00) 100%)",
    [RiskLevel.Four]:
      "linear-gradient(180deg, rgba(138, 185, 255, 0.30) 0%, rgba(138, 184, 255, 0.20) 50%, rgba(138, 184, 255, 0.00) 100%)",
    [RiskLevel.Five]:
      "linear-gradient(180deg, rgba(205, 163, 255, 0.30) 0%, rgba(205, 163, 255, 0.20) 50%, rgba(205, 163, 255, 0.00) 100%)",
  }
}
